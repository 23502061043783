import React from "react";
import HeaderWeb from "./WebComponents/HeaderWeb";
import HeaderMobile from "./MobileComponents/HeaderMobile";

const Header = () => {
  return (
    <>
      <HeaderWeb display={{ xs: "none", md: "flex" }} />
      <HeaderMobile display={{ xs: "flex", md: "none" }} />
    </>
  );
};
export default Header;
