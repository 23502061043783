import { Dialog, IconButton } from "@mui/material";
import NewsletterComponent from "./NewsletterComponent";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const NewsletterDialog = () => {
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen
      sx={{ margin: 3 }}
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        href="/"
        sx={{
          position: "sticky",
          left: "96%",
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon
          sx={{
            color: "common.white",
            background:
              "radial-gradient(circle, rgba(238,80,48,1) 50%, rgba(190,73,44,1) 100%)",
            borderRadius: 0.5,
          }}
        />
      </IconButton>
      <NewsletterComponent popover={true} />
    </Dialog>
  );
};
export default NewsletterDialog;
