import React from "react";
import { Box, Divider, Grid, Link, Typography, Tooltip } from "@mui/material";
import { withTranslation, Trans } from "react-i18next";
import DiscordIcon from "../svgComponents/DiscordIcon";
import Logo from "../svgComponents/Logo";
import MediumIcon from "../svgComponents/MediumIcon";
import OpenSeaIcon from "../svgComponents/OpenSeaIcon";
import TikTokIcon from "../svgComponents/TikTokIcon";
import TwitterIcon from "../svgComponents/TwitterIcon";
import LinkedinIcon from "../svgComponents/LinkedinIcon";
import styled from "@emotion/styled";
import White_Pepper from "../assets/White_Pepper.pdf";
import OpenSeaEnabled from "../svgComponents/OpenSeaEnabled";
import DiscordEnabled from "../svgComponents/DiscordEnabled";
import MediumEnabled from "../svgComponents/MediumEnabled";
import TikTokEnabled from "../svgComponents/TikTokEnabled";
import TwitterEnabled from "../svgComponents/TwitterEnabled";
import LinkedinEnabled from "../svgComponents/LinkedinEnabled";

export const CustomLink = styled(Link)(
  ({ theme, color = theme.palette.grey[500] }) => ({
    ...{
      color: color,
      fontWeight: 600,
      textShadow: "0px 1px 1px #000000",
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
  })
);

export const externalLinks = [
  {
    title: <Trans>{"footer.openSeaTooltip"}</Trans>,
    ref: "https://opensea.io/collection/cryptochefs-io",
    icon: <OpenSeaIcon />,
    enabled: <OpenSeaEnabled />,
  },
  {
    title: <Trans>{"footer.twitterTooltip"}</Trans>,
    ref: "https://twitter.com/cryptochefs_io/",
    icon: <TwitterIcon />,
    enabled: <TwitterEnabled />,
  },
  {
    title: <Trans>{"footer.linkedinTooltip"}</Trans>,
    ref: "https://www.linkedin.com/company/cryptochefs/",
    icon: <LinkedinIcon />,
    enabled: <LinkedinEnabled />,
  },
  {
    title: <Trans>{"footer.mediumTooltip"}</Trans>,
    ref: "https://cryptochefs.medium.com/",
    icon: <MediumIcon />,
    enabled: <MediumEnabled />,
  },
  {
    title: <Trans>{"footer.discordTooltip"}</Trans>,
    ref: "https://discord.com/invite/JufpFYBdKG/",
    icon: <DiscordIcon />,
    enabled: <DiscordEnabled />,
  },
  {
    title: <Trans>{"footer.tikTokTooltip"}</Trans>,
    ref: "https://www.tiktok.com/@cryptochefs",
    icon: <TikTokIcon />,
    enabled: <TikTokEnabled />,
  },
];

const privacyPolicyAndCookies = [
  {
    title: <Trans>{"footer.privacyPolicy"}</Trans>,
    ref: "https://docs.google.com/document/d/1TzDtME8EREJiSk1k7B5nnuzrM3idY2NwKuyaNswfkwM/edit#heading=h.cfu9h9kq6nr9",
  },
  {
    title: <Trans>{"footer.cookies"}</Trans>,
    ref: "https://docs.google.com/document/d/1TzDtME8EREJiSk1k7B5nnuzrM3idY2NwKuyaNswfkwM/edit#heading=h.umdnvduvwjzi",
  },
];

const Footer = ({ t }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#111111",
        paddingBottom: 5,
        marginTop: 4,
        marginBottom: -12,
        marginX: -3
      }}
    >
      <Grid
        container
        pt={2}
        px={{ xs: 2, md: 10 }}
        alignItems="center"
        justifyContent={{ xs: "center", md: "space-between" }}
        rowSpacing={3.5}
      >
        <Grid
          item
          xs={12}
          md={4}
          textAlign={{ xs: "center", md: "left" }}
          order={{ xs: 1, md: 1 }}
        >
          <Logo sx={{ fontSize: "13rem", height: "fit-content" }} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={4}
          justifyContent="center"
          order={{ xs: 5, md: 2 }}
          my={2}
        >
          {externalLinks.map((item) => (
            <Grid item xs={2} textAlign="center" key={"external" + item.ref}>
              <Tooltip title={item.title}>
                <CustomLink underline="none" target="_blank" href={item.ref}>
                  {item.icon}
                </CustomLink>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={4}
          width="fit-content"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "center", md: "flex-end" }}
          alignItems="center"
          columnSpacing={1}
          rowSpacing={{ xs: 3, md: 0 }}
          order={{ xs: 2, md: 3 }}
        >
          {privacyPolicyAndCookies.map((item) => (
            <Grid item key={item.ref}>
              <Typography variant="h5">
                <CustomLink
                  underline="none"
                  target="_blank"
                  href={item.ref}
                  rel="nofollow noindex"
                >
                  {item.title}
                </CustomLink>
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} order={{ xs: 7, md: 4 }}>
          <Divider
            sx={{
              width: "100%",
              backgroundColor: "#E9E9E947",
              display: { xs: "none", md: "flex" },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} order={{ xs: 4, md: 5 }}>
          <Typography variant="h5" textAlign={{ xs: "center", md: "left" }}>
            <CustomLink
              underline="none"
              href="https://cryptochefs.io/"
              sx={{ margin: 0 }}
            >
              {t("footer.aboutCryptoChefs")}
            </CustomLink>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 6, md: 6 }}
          sx={{ paddingLeft: { xs: 0, md: 5 } }}
        >
          <Typography variant="h5" color="grey.500" textAlign="center">
            {t("footer.copyright1")}
            {new Date().getFullYear()}{" "}
            <CustomLink
              color="inherit"
              target="_blank"
              underline="always"
              href="https://spicy.fi/"
              rel="nofollow noindex"
              sx={{ margin: 0, "&:hover": { color: "common.white" } }}
            >
              {t("footer.copyright2")}
            </CustomLink>
            {t("footer.copyright3")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} order={{ xs: 3, md: 7 }}>
          <Typography variant="h5" textAlign={{ xs: "center", md: "right" }}>
            <CustomLink
              underline="none"
              target="_blank"
              href={White_Pepper}
              rel="nofollow noindex"
              sx={{ margin: 0, display:{md: "none"}}}
            >
              {t("footer.whitePepper")}
            </CustomLink>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation()(Footer);
