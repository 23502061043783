import React from "react";
import { SvgIcon } from "@mui/material";

function TwitterIcon(props) {
  return (
    <SvgIcon viewBox="0 0 25.245 20.543" {...props}>
      <path
        id="Icon_ionic-logo-twitter"
        data-name="Icon ionic-logo-twitter"
        d="M26.658,6.934a10.471,10.471,0,0,1-2.974.819,5.2,5.2,0,0,0,2.279-2.867,10.292,10.292,0,0,1-3.29,1.257,5.178,5.178,0,0,0-8.961,3.541,5.071,5.071,0,0,0,.134,1.182A14.678,14.678,0,0,1,3.168,5.447a5.19,5.19,0,0,0,1.61,6.922,5.059,5.059,0,0,1-2.354-.647v.064a5.184,5.184,0,0,0,4.157,5.082,5.214,5.214,0,0,1-1.364.182,4.886,4.886,0,0,1-.974-.1,5.188,5.188,0,0,0,4.841,3.6,10.384,10.384,0,0,1-6.436,2.22A10.5,10.5,0,0,1,1.413,22.7a14.477,14.477,0,0,0,7.928,2.343A14.649,14.649,0,0,0,24.091,10.288c0-.225-.005-.449-.016-.669A10.543,10.543,0,0,0,26.658,6.934Z"
        transform="translate(-1.413 -4.5)"
        fill="rgba(255, 255, 255, 0.9)"
      />
    </SvgIcon>
  );
}

export default TwitterIcon;
