import React from "react";
import { SvgIcon } from "@mui/material";

function MediumEnabled(props) {
  return (
    <SvgIcon viewBox="0 0 20.543 20.543" {...props}>
      <path
        id="Icon_awesome-medium"
        data-name="Icon awesome-medium"
        d="M0,2.25V22.793H20.543V2.25ZM17.067,7.115l-1.1,1.055a.323.323,0,0,0-.124.307V16.24a.316.316,0,0,0,.124.307L17.044,17.6v.234H11.633v-.225l1.114-1.082c.11-.11.11-.142.11-.307V9.944l-3.1,7.869H9.341L5.732,9.944v5.273a.725.725,0,0,0,.2.605l1.449,1.756v.234H3.265v-.234l1.449-1.756a.7.7,0,0,0,.188-.605v-6.1a.524.524,0,0,0-.174-.449L3.439,7.115V6.881h4l3.091,6.786,2.719-6.782h3.815Z"
        transform="translate(0 -2.25)"
        fill="#fff"
      />
    </SvgIcon>
  );
}

export default MediumEnabled;
