import React from "react";
import CookieConsent from "react-cookie-consent";
import { Typography } from "@mui/material";
import cookie from "../assets/cookies.svg";
import { TermsAndConditions } from "../utils/theme";
import { theme } from "../utils/theme";
import { useMediaQuery } from "@mui/material";

const Cookies = () => {
  const dimensions = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton
      declineButtonText="Decline"
      buttonText="Allow"
      cookieName="Cookies"
      style={{
        height: "fit-content",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 35px #00000029",
        border: "1px solid #7070701A",
        borderRadius: "16px",
        color: "#464445",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        zIndex: "99999999999",
        margin: `${dimensions ? "20%" : "4%"}`,
        marginRight: `${dimensions ? "20%" : "4%"}`,
        marginBottom: "10px",
      }}
      buttonStyle={{
        width: "110px",
        height: "55px",
        color: "#464445",
        background: "#FFCE3C1A 0% 0% no-repeat padding-box",
        border: "1px solid #CE72304D",
        borderRadius: "10px 25px 25px 10px",
        fontSize: "1rem",
        FontFamily: "Rubik",
        fontWeight: 600,
      }}
      declineButtonStyle={{
        width: "110px",
        height: "55px",
        color: "#464445",
        background: "#00000005 0% 0% no-repeat padding-box",
        border: "1px solid #1D23354D",
        borderRadius: "25px 10px 10px 25px",
        fontSize: "1rem",
        FontFamily: "Rubik",
        fontWeight: 600,
        marginLeft: "10px",
      }}
      expires={150}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
          width: "100%",
        }}
      >
        <img
          src={cookie}
          max-width="100%"
          height="auto"
          width="auto"
          alt="cookie"
        ></img>
        <Typography variant="h5" style={{ marginLeft: "10px" }}>
          We use tracking technologies to analyze site traffic. Learn more about
          our{" "}
          <TermsAndConditions
            target="blank"
            href={
              "https://docs.google.com/document/d/1TzDtME8EREJiSk1k7B5nnuzrM3idY2NwKuyaNswfkwM/edit#heading=h.cfu9h9kq6nr9"
            }
          >
            privacy policy
          </TermsAndConditions>{" "}
          and{" "}
          <TermsAndConditions
            target="blank"
            href="https://docs.google.com/document/d/1TzDtME8EREJiSk1k7B5nnuzrM3idY2NwKuyaNswfkwM/edit#heading=h.l990idjd555l"
          >
            terms and conditions
          </TermsAndConditions>
          .
        </Typography>
      </div>
    </CookieConsent>
  );
};
export default Cookies;
