import React from "react";
import { SvgIcon } from "@mui/material";

function DiscordIcon(props) {
  return (
    <SvgIcon viewBox="0 0 20.573 23.512" {...props}>
      <path
        id="Icon_awesome-discord"
        data-name="Icon awesome-discord"
        d="M13.649,11.168a1.2,1.2,0,1,1-1.2-1.3A1.252,1.252,0,0,1,13.649,11.168Zm-5.49-1.3a1.31,1.31,0,0,0,0,2.61,1.252,1.252,0,0,0,1.2-1.3A1.245,1.245,0,0,0,8.159,9.863ZM20.573,2.422v21.09c-2.962-2.617-2.014-1.751-5.455-4.949l.623,2.175H2.41A2.416,2.416,0,0,1,0,18.316V2.422A2.416,2.416,0,0,1,2.41,0H18.163A2.416,2.416,0,0,1,20.573,2.422Zm-3.35,11.145A15.743,15.743,0,0,0,15.53,6.713a5.813,5.813,0,0,0-3.3-1.234l-.165.188a7.824,7.824,0,0,1,2.927,1.493,9.989,9.989,0,0,0-8.782-.341c-.435.2-.694.341-.694.341a7.931,7.931,0,0,1,3.092-1.54l-.118-.141a5.813,5.813,0,0,0-3.3,1.234,15.743,15.743,0,0,0-1.693,6.854,4.262,4.262,0,0,0,3.586,1.787s.435-.529.788-.976A3.656,3.656,0,0,1,5.807,12.99c.173.121.458.278.482.294a8.569,8.569,0,0,0,7.336.411A6.727,6.727,0,0,0,14.977,13a3.71,3.71,0,0,1-2.128,1.4c.353.447.776.952.776.952A4.3,4.3,0,0,0,17.222,13.566Z"
        fill="rgba(255, 255, 255, 0.9)"
      />
    </SvgIcon>
  );
}

export default DiscordIcon;
