import { lazy, Suspense } from "react";
// fonts
import "@fontsource/rubik";
import "@fontsource/luckiest-guy";
import React from "react";
import { Route, Routes } from "react-router-dom";
// material-ui
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { CssBaseline, Container } from "@mui/material";
// components
import Cookies from "./components/CookieConsent";
import AboutCryptoChefsComponent from "./components/AboutCryptoChefsComponent";
import HeroComponent from "./components/HeroComponent";
import Header from "./components/Header";
import NewsletterDialog from "./components/NewsletterDialog";
import CryptonaireButton from "./components/CryptonaireButton";
const JourneyComponent = lazy(() => import("./components/JourneyComponent"));
const MeetChefsComponent = lazy(() =>
  import("./components/MeetChefsComponent")
);
const TeamComponent = lazy(() => import("./components/TeamComponent"));
const PartnersComponent = lazy(() => import("./components/PartnersComponent"));
const FaqComponent = lazy(() => import("./components/FaqComponent"));
const AdvisorsComponent = lazy(() => import("./components/AdvisorsComponent"));
const HowItWorksComponent = lazy(() =>
  import("./components/HowItWorksComponent")
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense
        fallback={
          <>
            <Header />
            <HeroComponent />
          </>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <Container disableGutters maxWidth="xl">
                <Header />
                <HeroComponent />
                <AboutCryptoChefsComponent />
                <HowItWorksComponent />
                <MeetChefsComponent />
                <JourneyComponent />
                <TeamComponent />
                <AdvisorsComponent />
                <PartnersComponent />
                <FaqComponent />
                <Cookies />
                <CryptonaireButton />
              </Container>
            }
          />
          <Route path="/newsletter" element={<NewsletterDialog />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
