import React from "react";
import { Grid, AppBar, Toolbar } from "@mui/material";
import LogoBlack from "../../svgComponents/LogoBlack";
import HeaderModal from "../HeaderModal";
import CryptonaireButton from "../CryptonaireButton";

const HeaderMobile = ({ display }) => {
  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          zIndex: 999999999,
          height: { xs: "80px", md: "5vw" },
          minHeight: { xs: "80px", md: "60px" },
          maxHeight: "100px",
          marginBottom: 7,
          width: "100%",
          display: display,
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <LogoBlack
                sx={{
                  fontSize: { xs: 100 },
                  marginTop: { xs: "30%" },
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              <CryptonaireButton footer={true} />
              <Grid item>
                <HeaderModal />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default HeaderMobile;
