import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Fade,
  Modal,
  Backdrop,
} from "@mui/material";
import White_Pepper from "../assets/White_Pepper.pdf";
import PlayNowButton from "../svgComponents/PlayNowButton";
import { CustomLink, externalLinks } from "./Footer";
import MenuIcon from "@mui/icons-material/Menu";
import OpenBookImage from "../svgComponents/OpenBookImage";
import CloseIcon from "@mui/icons-material/Close";

const HeaderModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div>
      <IconButton shape="squarish" onClick={handleClick}>
        {open ? (
          <CloseIcon sx={{ color: "common.white", fontSize: 36 }} />
        ) : (
          <MenuIcon sx={{ color: "common.white", fontSize: 36 }} />
        )}
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: {
            backgroundColor: "grey.900",
            zIndex: -1,
          },
        }}
      >
        <Fade in={open}>
          <Stack alignItems="center" spacing={3} pt={10} height="100vh">
            <OpenBookImage
              sx={{ fontSize: 340, paddingLeft: 4, marginBottom: -5 }}
            />
            <CustomLink
              underline="none"
              target="_blank"
              href={White_Pepper}
              rel="nofollow noindex"
              onClick={handleClose}
            >
              {t("footer.whitePepper")}
            </CustomLink>
            <CustomLink
              underline="none"
              target="_blank"
              href="https://discord.com/invite/JufpFYBdKG/"
              rel="nofollow noindex"
            >
              {t("header.joinDiscordButton")}
            </CustomLink>
            <Grid
              container
              justifyContent="space-between"
              paddingX={{ xs: 4, sm: 10 }}
            >
              {externalLinks.map((item) => (
                <Grid container item xs={1} justifyContent="center" key={item.title}>
                  <Grid item>
                    <Button
                      variant="transparent"
                      underline="none"
                      target="_blank"
                      href={item.ref}
                      textAlign="center"
                    >
                      {item.icon}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Button
              href="https://app.cryptochefs.io/"
              sx={{ height: "28vw", width: "52vw", borderRadius: 0 }}
            >
              <PlayNowButton
                sx={{
                  fontSize: { xs: "50vw", sm: 300 },
                  height: "28vw",
                }}
              />
            </Button>
          </Stack>
        </Fade>
      </Modal>
    </div>
  );
};
export default HeaderModal;
