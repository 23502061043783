import { createTheme } from "@mui/material";
import styled from "styled-components";
import playButton from "../assets/play-button.svg";
import subscribeButton from "../assets/subscribeButton.svg";
import pattern from "../assets/pattern.png";
import topBarBackground from "../assets/TopBarBackground.svg";

export let theme = createTheme({
  palette: {
    primary: {
      main: "#BADBF6",
    },
    secondary: {
      main: "#FFFFFF",
    },
    tertiary: {
      main: "#FFCE3C",
    },
    black: {
      main: "#000000",
    },
    icon: {
      main: "#C2C2C2",
    },
  },
  shape: {
    borderRadius: 25,
  },
  typography: {
    fontFamily: "Rubik, 'Helvetica', 'Arial', sans-serif",
  },
});

theme = createTheme(theme, {
  typography: {
    h1: {
      fontFamily: "Luckiest Guy, Rubik, 'Helvetica', 'Arial', sans-serif",
      fontSize: "4.5rem",
      color: "white",
      height: "10vh",
      fontWeight: "normal",
      paddingTop: "20px",
      textAlign: "center",
      WebkitTextStroke: "0.15rem black",
      textShadow: "0px 5px 0px #000000",
      "@media (max-width:900px)": {
        fontSize: "3.5rem",
        marginBottom: "50px",
      },
      "@media (max-width:350px)": {
        fontSize: "3.2rem",
        marginBottom: "60px",
      },
    },
    h2: {
      fontSize: "2.5rem",
      fontFamily: "Luckiest Guy",
      marginTop: "150px",
      color: "#464445",
      letterSpacing: "0.27px",
      textAlign: "center",
      fontWeight: "normal",
      "@media (max-width:900px)": {
        fontSize: "1.9rem",
      },
      "@media (max-width:350px)": {
        fontSize: "1.7rem",
      },
    },
    h3: {
      fontSize: "1.715rem",
      fontWeight: "normal",
      color: "#2B2825",
      letterSpacing: "0.27px",
      "@media (max-width:900px)": {
        fontSize: "1.3034rem",
        textAlign: "center",
      },
      "@media (max-width:350px)": {
        fontSize: "1.2005rem",
        textAlign: "center",
      },
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: "normal",
      color: "#2B2825",
      "@media (max-width:900px)": {
        fontSize: "1rem",
      },
      "@media (max-width:350px)": {
        fontSize: "0.83rem",
      },
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: "normal",
      color: "#000000",
      textTransform: "none",
      "@media (max-width:900px)": {
        fontSize: "0.9rem",
      },
      "@media (max-width:350px)": {
        fontSize: "0.72rem",
      },
    },
    h6: {
      fontSize: "0.588245rem",
      fontWeight: "normal",
      color: "#000000",
      textTransform: "none",
      "@media (max-width:900px)": {
        fontSize: "0.4470662rem",
      },
      "@media (max-width:350px)": {
        fontSize: "0.41177rem",
      },
    },
    h4Bold: {
      fontSize: "1.25rem",
      textAlign: "center",
      fontWeight: "bold",
      color: "#2B2825",
      "@media (max-width:900px)": {
        fontSize: "1rem",
      },
      "@media (max-width:350px)": {
        fontSize: "0.83rem",
      },
    },
    subtitle: {
      fontSize: "0.8rem",
      fontWeight: "700",
      color: "#777E90",
      textTransform: "none",
      "@media (max-width:900px)": {
        fontSize: "0.7rem",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: theme.palette.primary.main,
          color: "#2D2A27",
          fontFamily: "Rubik",
          maxWidth: "1650px",
        },
        html: {
          display: "flex",
          justifyContent: "center",
          background: `transparent url(${pattern}) repeat right`,
          backgroundSize: "25%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: "capitalize",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "100%",
          maxHeight: "60px",
          maxWidth: "250px",
          borderRadius: "9px",
          backgroundColor: "#FFE655",
          background:
            "radial-gradient(circle at top left, rgba(255,236,141,1) 6%, #FFE655 2%)",
          borderBottom: "3px solid #3A2717",
          boxShadow:
            "inset 0 -8px 0 hsl(34, 100%, 60%), inset 0 -30px 0 hsl(44, 100%, 62%)",
          color: theme.palette.common.black,
          "&:hover, &.Mui-focusVisible": {
            transform: "scale(1.02)",
            background:
              "radial-gradient(circle at top left, rgba(255,236,141,1) 6%, #FFE655 2%)",
            borderBottom: "2px solid #3A2717",
            boxShadow:
              "inset 0 -8px 0 hsl(34, 100%, 60%), inset 0 -30px 0 hsl(44, 100%, 62%)",
          },
          "@media (max-width:700px)": {
            fontSize: "64%",
            padding: "1vh 2vw 1vh 2vw",
          },
        },
        transparent: {
          backgroundColor: "transparent",
          "&:hover, &.Mui-focusVisible": {
            transform: "scale(1.3)",
            color: theme.palette.common.white,
          },
        },
      },
      variants: [
        {
          props: { bg: "yellowContained" },
          style: {
            backgroundColor: "#FFE655",
            background:
              "radial-gradient(circle at top left, rgba(255,236,141,1) 6%, #FFE655 2%)",
            borderBottom: "2px solid #3A2717",
            boxShadow:
              "inset 0 -8px 0 hsl(34, 100%, 60%), inset 0 -30px 0 hsl(44, 100%, 62%)",
            borderRadius: "9px",
            color: theme.palette.common.black,
            "&:hover": {
              transform: "scale(1.02)",
            },
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
      ],
    },
    MuiAvatar: {
      styleOverrides: {
        circular: {
          backgroundColor: theme.palette.secondary.main,
          width: "2rem",
          height: "2rem",
          color: theme.palette.common.black,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "rgba(255, 255, 255, 0.1)",
          color: theme.palette.secondary.main,
          minHeight: "90px",
          borderRadius: "11px",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          height: "100%",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          [theme.breakpoints.up("md")]: {
            width: "100%",
          },
          "&.MuiDialog-paper": {
            background:
              "transparent radial-gradient(closest-side at 50% 50%, #C6E2F4 0%, #98C6E5 100%) 0% 0% no-repeat padding-box",
            boxShadow: "none",
            display: "block",
            borderRadius: 30,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          marginLeft: "2.5vw",
          color: "white",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "250px",
          height: "auto",
          opacity: "0.2",
          paddingBottom: "2vw",
          position: "relative",
          zIndex: "-9999",
          [theme.breakpoints.down("md")]: {
            width: "120px",
            height: "auto",
            margin: "30px -40px 50px -40px",
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: "transparent",
      },
      styleOverrides: {
        root: {
          background: `transparent url(${topBarBackground}) no-repeat center`,
          backgroundSize: "cover",
          boxShadow: "none",
          color: theme.palette.grey[200],
          [theme.breakpoints.down("md")]: {
            backgroundSize: "auto",
          },
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { shape: "squarish" },
          style: {
            borderRadius: 9,
            backgroundColor: theme.palette.grey[600],
            margin: 8,
            width: "12vw",
            height: "12vw",
            maxWidth: "40px",
            maxHeight: "40px",
            "&:hover": {
              transform: "scale(1.02)",
              backgroundColor: theme.palette.grey[500],
            },
          },
        },
      ],
    },
  },
});

export const NextButton = styled.div`
  margin-top: -75px;
  margin-right: -10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const BackButton = styled.div`
  margin-top: -85px;
  margin-left: -10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ElipseTop = styled.div`
  width: 100%;
  height: 9vw;
  background-color: #badbf6;
  clip-path: ellipse(67% 220% at 50% -120%);
`;

export const ElipseBottom = styled.div`
  width: 100%;
  height: 9vw;
  margin-top: 6vw;
  background-color: #badbf6;
  clip-path: ellipse(67% 220% at 50% 220%);
`;

export const PlayButton = styled.input`
  cursor: pointer;
  width: 165px;
  border-radius: 50px;
  height: 140px;
  border: none;
  outline: none;
  background: transparent url(${playButton}) no-repeat center;
  background-size: contain;
  color: white;
  font-family: Luckiest Guy;
  font-size: 44px;
  text-align: center;
  padding-right: 18%;
  &:hover {
    transform: scale(1.02);
  }
`;

export const TeamButton = styled.button`
  width: 100%;
  border-radius: 200px;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  padding: 0;
`;

export const TermsAndConditions = styled.a`
  color: rgb(70, 68, 69);
`;

export const NewsletterInput = styled.input`
  height: 46px;
  width: 230px;
  background-color: #57514e;
  border: none;
  box-shadow: inset 0 0 10px #404040;
  border-radius: 7px;
  outline: none;
  font-size: 16px;
  color: white;
  padding-left: 12px;
  &::placeholder {
    color: lightgrey;
  }
`;

export const InputButton = styled.input`
  cursor: pointer;
  width: 180px;
  border-radius: 50px;
  height: 75px;
  border: none;
  outline: none;
  background: transparent url(${subscribeButton}) no-repeat center;
  background-size: cover;
  color: white;
  font-family: Luckiest Guy;
  padding-top: 10px;
  font-size: 26px;
  &:hover {
    transform: scale(1.04);
  }
`;

export const PartnersImageHover = styled.div`
  &:hover {
    transform: scale(1.02);
  }
`;
