import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, AppBar, Toolbar } from "@mui/material";
import White_Pepper from "../../assets/White_Pepper.pdf";
import LogoBlack from "../../svgComponents/LogoBlack";
import PlayNowButton from "../../svgComponents/PlayNowButton";
import { CustomLink, externalLinks } from "../Footer";
import WhitePepperIcon from "../../assets/whitepepper.svg";
import { theme } from "../../utils/theme";

const HeaderWeb = ({ display }) => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(null);
  const handleOver = (item) => {
    setEnabled(item.ref);
  };
  const handleOut = () => {
    setEnabled(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: 999999999,
        height: { xs: "none", md: "5vw" },
        minHeight: { xs: "none", md: "60px" },
        maxHeight: "100px",
        marginBottom: 7,
        display: display,
      }}
    >
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            container
            item
            justifyContent="flex-start"
            alignItems="center"
            xs={5}
            lg={6}
          >
            <Grid item>
              <LogoBlack
                sx={{
                  fontSize: { md: 80, lg: 100, xl: 120 },
                  marginTop: { md: "35%", lg: "40%", xl: "45%" },
                  marginLeft: "15%",
                }}
              />
            </Grid>
            <Grid
              container
              item
              md={6.5}
              lg={4.5}
              xl={4}
              justifyContent="flex-end"
              alignItems="center"
              maxWidth="fit-content"
            >
              <Grid item mr="-2vw" mt={1}>
                <img
                  src={WhitePepperIcon}
                  alt="white-pepper-icon"
                  width="45rem"
                  height="auto"
                />
              </Grid>
              <Grid
                item
                width="fit-content"
                sx={{
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CustomLink
                  underline="none"
                  target="_blank"
                  href={White_Pepper}
                  rel="nofollow noindex"
                  color={theme.palette.grey[200]}
                  fontSize="1rem"
                >
                  {t("footer.whitePepper")}
                </CustomLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            xs={7}
            lg={6}
          >
            <Grid container item xs={6.5}>
              {externalLinks.map((item) => (
                <Grid
                  item
                  xs={2}
                  textAlign="center"
                  key={"external" + item.ref}
                >
                  <Button
                    variant="transparent"
                    underline="none"
                    target="_blank"
                    href={item.ref}
                    onMouseOver={() => handleOver(item)}
                    onMouseOut={handleOut}
                  >
                    {enabled === item.ref ? item.enabled : item.icon}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={4}>
              <Button
                href="https://app.cryptochefs.io/"
                sx={{
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <PlayNowButton
                  sx={{
                    fontSize: { md: 160, lg: 180, xl: 230 },
                    marginTop: { md: "10%", lg: "12%", xl: "8%" },
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default HeaderWeb;
