import React from "react";
import { withTranslation } from "react-i18next";
import { Box, Typography, Grid } from "@mui/material";
import { ElipseTop, ElipseBottom } from "../utils/theme";
import AboutImage from "../svgComponents/AboutImage";

const AboutCryptoChefsComponent = ({ t }) => {
  return (
    <Box sx={{ backgroundColor: "secondary.main" }}>
      <ElipseTop />
      <Grid container flexDirection="column" alignItems="center" pb={10}>
        <Typography variant="h2" mt={15} mb={5}>
          {t("aboutCryptoChefsComponent.title")}
        </Typography>
        <AboutImage sx={{ fontSize: { xs: "18rem", md: "28rem" } }} />
        <Grid item xs={12} sm={6} textAlign="center" px={3}>
          <Typography variant="h4">
            {t("aboutCryptoChefsComponent.text")}
          </Typography>
        </Grid>
      </Grid>
      <ElipseBottom />
    </Box>
  );
};

export default withTranslation()(AboutCryptoChefsComponent);
