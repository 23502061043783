import React from "react";
import { withTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";
import mainImage from "../assets/hero.svg";
import { PlayButton } from "../utils/theme.js";
import styled from "@emotion/styled";
import Rock7 from "../assets/Rocks/rock7.svg";
import Rock2 from "../assets/Rocks/rock2.svg";
import Rock8 from "../assets/Rocks/rock8.svg";

export const RocksContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  backgroundColor: "transparent",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    visibility: "hidden",
  },
}));

const HeroComponent = ({ t }) => {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item px={2}>
          <Typography variant="h1">
            {t("heroComponent.learnCookEarn")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          width="100vw"
          height="750px"
          justifyContent="center"
          alignItems="center"
          xs={12}
          sx={{
            background: `transparent url(${mainImage}) no-repeat center`,
            backgroundSize: "1300px 718.94px",
          }}
        >
          <Grid item>
            <a href="https://app.cryptochefs.io/">
              <PlayButton type="submit" value=""></PlayButton>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <RocksContainer>
        <img
          src={Rock7}
          height="auto"
          width="auto"
          alt="Rock7"
          style={{ position: "absolute", left: "20%", top: -200 }}
        />
        <img
          src={Rock2}
          height="auto"
          width="auto"
          alt="Rock2"
          style={{ position: "absolute", right: "20%", top: -220 }}
        />
        <img
          height="auto"
          width="auto"
          alt="Rock8"
          src={Rock8}
          style={{ position: "absolute", left: "40%", top: -85 }}
        />
        <img
          src={Rock2}
          height="auto"
          width="auto"
          alt="Rock2"
          style={{
            position: "absolute",
            left: "30%",
            top: 90,
            zIndex: 9999999,
            transform: "rotate(-15deg)",
          }}
        />
      </RocksContainer>
    </Grid>
  );
};
export default withTranslation()(HeroComponent);
