import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { withTranslation } from "react-i18next";
import { Typography, Stack, Box } from "@mui/material";
import NewsletterImage from "../svgComponents/NewsletterImage";
import { NewsletterInput, InputButton } from "../utils/theme.js";

const NewsletterComponent = ({ t, popover = false }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      popover && success && navigate("/");
    }, 6000);
  }, [success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email) return;
    const server_response = await submit_server_form(email, name);

    if (server_response.success) {
      setSuccess(true);
      setName("");
      setEmail("");
      setMessage(t("newsletterComponent.submitWithSuccessMessage"));
    } else {
      setMessage(server_response.message);
    }
  };

  const submit_server_form = async (email, name) => {
    const url = new URL("https://api.sender.net/v2/subscribers");

    let headers = {
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZDgxMTQ2MzdmZmU0ZGIwNWFhMGViMTA1ZDY1OGVmZGQ0MDVmOGJjYTdhYTdlYWE0MWY1ODYwNjVlMWQ1NTJkNjE1NDRjNmNjMTVjOGI4MTMiLCJpYXQiOjE2NTk1MjczNDMuMjM0MTQ4LCJuYmYiOjE2NTk1MjczNDMuMjM0MTYyLCJleHAiOjQ4MTMxMzA5NDMuMjMxMDk1LCJzdWIiOiIxNDM1OTQiLCJzY29wZXMiOltdfQ.dXpEhsCcLXf-tzaak--C-6oP_Ewhukk7WjsxWflImFbQdpWgJ3Hi1NTYIl_Aq5qA2eJ24aE9KiFCJVc8dN6Q7g",
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let data = {
      email: email,
      firstname: name,
      groups: ["dROzYR"],
    };

    const response = fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }).then((response) => response.json());

    return response;
  };

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      maxWidth="100vw"
      mt={-10}
      mb={-60}
      sx={{
        overflow: "hidden",
      }}
    >
      <Box>
        <Typography variant="h2" mb={1.5}>
          {t("newsletterComponent.title")}
        </Typography>
        <Typography variant="h4" textAlign="center" mb={5} px={2}>
          {t("newsletterComponent.subTitle")}
        </Typography>
      </Box>
      <NewsletterImage sx={{ width: "50rem", height: "auto" }} />
      <form onSubmit={handleSubmit} style={{ width: "500px" }}>
        <Stack
          spacing={2.5}
          alignItems="center"
          sx={{ position: "relative", bottom: 265, width: "100%" }}
        >
          <NewsletterInput
            required
            type="text"
            placeholder={t("newsletterComponent.namePlaceholder")}
            name="firstname"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <NewsletterInput
            required
            type="email"
            placeholder={t("newsletterComponent.emailPlaceholder")}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputButton type="submit" value="" style={{ paddingRight: 8 }} />
          {success && (
            <Box sx={{ maxWidth: { xs: "50%", sm: "80%", lg: "90%" } }}>
              <Typography
                variant="h5"
                fontWeight="400"
                color="grey.800"
                textAlign="center"
              >
                {popover
                  ? message + " You will be redirected to our landing page."
                  : message}
              </Typography>
            </Box>
          )}
        </Stack>
      </form>
    </Stack>
  );
};

export default withTranslation()(NewsletterComponent);
