import { useEffect, forwardRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import cryptonaireLogo from "../assets/cryptonaire_logo.svg";
import cryptonaireButton from "../assets/Cryptonaire_button.svg";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeForiOS from "../svgComponents/QrCodeForiOS";
import AndroidQrCode from "../svgComponents/AndroidQrCode";
import ScanMe from "../svgComponents/ScanMe";
import PlayStoreSmall from "../svgComponents/PlayStoreSmall";
import AppleSmallBlack from "../svgComponents/AppleSmallBlack";

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const StoresButton = [
  {
    title: "Android",
    activeIcon: <PlayStoreSmall sx={{ fontSize: "1rem" }} />,
    type: "android",
    link: "https://play.google.com/store/apps/details?id=fi.spicy.cryptonaire",
  },
  {
    title: "iOS",
    activeIcon: <AppleSmallBlack sx={{ fontSize: "1rem", marginTop: -0.6 }} />,
    type: "ios",
    link: "https://apps.apple.com/gb/app/cryptonaire/id6444831636?uo=2",
  },
];

const CryptonaireButton = ({ footer }) => {
  const [open, setOpen] = useState(false);
  const [buttonType, setButtonType] = useState("android");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document
      .getElementById(window.location.hash)
      ?.scrollIntoView({ behavior: "smooth" });
  }, [open]);

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          position: "sticky",
          right: "0vw",
          bottom: "0vh",
          zIndex: 9999999,
          textAlign: "right",
        }}
      >
        <Button
          variant="squarish"
          onClick={handleClickOpen}
          sx={{
            background: `transparent url(${cryptonaireButton}) no-repeat center`,
            backgroundSize: "100%",
            borderRadius: 0,
            padding: 2.5,
            marginRight: 1,
            "&:hover": {
              transform: "scale(1.04)",
            },
          }}
        >
          <img
            src={cryptonaireLogo}
            width="48rem"
            height="auto"
            alt="cryptonaire logo"
          />
          <Typography
            variant="h2"
            color="common.white"
            fontSize="1rem"
            marginY={0}
            marginLeft={1}
          >
            Get the app
          </Typography>
        </Button>
      </Box>
      <Box
        mr={1.5}
        sx={{
          display: { xs: footer ? "block" : "none", md: "none" },
        }}
      >
        <Button onClick={handleClickOpen}>
          <img
            src={cryptonaireLogo}
            width="42rem"
            height="auto"
            alt="cryptonaire logo"
          />
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          zIndex: 99999999,
        }}
        PaperProps={{
          sx: {
            width: { xs: "92vw", md: "85vw", lg: "70vw", xl: "60vw" },
            maxWidth: "1000px",
            "&.MuiDialog-paper": {
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 25px #00000029",
              borderRadius: "11px",
              height: "fit-content",
            },
          },
        }}
      >
        <Grid container justifyContent="center">
          <Grid
            container
            item
            xs={12}
            sm={7}
            sx={{
              borderRight: { xs: "none", sm: "1px solid #00000029" },
            }}
            alignItems="center"
            justifyContent={{ xs: "center", sm: "space-between" }}
            columnGap={3}
            px={{ xs: 2, md: 4, lg: 6 }}
            py={{ xs: 5, sm: 0 }}
          >
            <Grid item xs={3} sm={2.8} textAlign="center">
              <img src={cryptonaireLogo} width="100%" alt="cryptonaire logo" />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={8}
              flexDirection="column"
              justifyContent="space-between"
              textAlign={{ xs: "center", sm: "left" }}
            >
              <Grid item xs={12} marginTop={{ xs: 1, sm: -2 }}>
                <Typography
                  variant="h4Bold"
                  fontSize={{ xs: "1.55rem", sm: "1.3rem", md: "1.5rem" }}
                >
                  Cryptonaire
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Typography variant="subtitle">
                  Fun play to earn Quiz app
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle">
                  Brought to you by CryptoChefs
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item order={1} textAlign="right">
            <IconButton
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                zIndex: 99999,
                position: "absolute",
                right: 8,
                top: 8,
                "&:hover": {
                  backgroundColor: "secondary.main",
                },
              }}
            >
              <CloseIcon
                sx={{
                  color: "common.white",
                  borderRadius: "5px",
                  background:
                    "radial-gradient(circle, rgba(238,80,48,1) 50%, rgba(190,73,44,1) 100%)",
                  fontSize: "1.5rem",
                }}
              />
            </IconButton>
          </Grid>
          <Grid container item xs={10} sm={5} flexDirection="column" py={6}>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              order={2}
              px={{ xs: 2, sm: 1.5, lg: 5, xl: 6 }}
              display={{ xs: "none", sm: "flex" }}
            >
              <Grid item>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <ScanMe
                      sx={{
                        fontSize: "1rem",
                        height: "auto",
                        marginRight: 2,
                        marginTop: 1.5,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle" fontWeight="400">
                      Scan Me
                    </Typography>
                  </Grid>
                  {buttonType === "android" ? (
                    <Grid container item justifyContent="center">
                      <Grid item xs={12} textAlign="center">
                        <AndroidQrCode
                          sx={{
                            fontSize: { xs: "10.2rem", xl: "12.2rem" },
                            bgcolor: "common.white",
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item justifyContent="center" rowGap={2}>
                      <Grid item xs={12} textAlign="center">
                        <QrCodeForiOS
                          sx={{
                            fontSize: { xs: "10.2rem", xl: "12.2rem" },
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                {StoresButton.map((store) => (
                  <Grid item xs={6} key={store.type}>
                    <Button
                      fullWidth
                      sx={{
                        border: "1px solid lightgrey",
                        padding: 1.5,
                        borderRadius:
                          store.type === "android"
                            ? "25px 0px 0px 25px"
                            : "0px 25px 25px 0px",
                        backgroundColor:
                          buttonType === store.type
                            ? "grey.200"
                            : "transparent",
                        boxShadow: 0,
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: "grey.200",
                        },
                      }}
                      onClick={() => setButtonType(store.type)}
                    >
                      {store.activeIcon}
                      <Typography
                        variant="h5"
                        ml={0.5}
                        fontSize="0.8rem"
                        color="common.black"
                        sx={{
                          cursor: "inherit",
                        }}
                      >
                        {store.title}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              flexDirection="column"
              display={{ xs: "flex", sm: "none" }}
              rowGap={2.5}
              mt={-5}
            >
              {StoresButton.map((store) => (
                <Button bg="yellowContained" fullWidth href={store.link}>
                  {store.activeIcon}
                  <Typography
                    variant="h5"
                    ml={0.5}
                    fontSize="0.8rem"
                    color="common.black"
                    sx={{
                      cursor: "inherit",
                    }}
                  >
                    {store.title}
                  </Typography>
                </Button>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default CryptonaireButton;
