import React from "react";
import { SvgIcon } from "@mui/material";

function TikTokEnabled(props) {
  return (
    <SvgIcon viewBox="0 0 20.551 19.999" {...props}>
      <path
        id="TickTock"
        d="M7448.594-312h-16.636a1.98,1.98,0,0,1-1.957-2v-16a1.98,1.98,0,0,1,1.957-2h16.636a1.981,1.981,0,0,1,1.958,2v16A1.981,1.981,0,0,1,7448.594-312Zm-10.72-11.908a4.937,4.937,0,0,0-3.227,1.238,5.092,5.092,0,0,0-1.7,3.347c-.016.354-.006.707.005,1.006a5.1,5.1,0,0,0,2.412,3.854,4.758,4.758,0,0,0,2.449.7c.091,0,.171,0,.245-.007a4.878,4.878,0,0,0,3.9-2.165,5.267,5.267,0,0,0,.891-2.659c.014-1.3.013-2.67.012-3.77,0-.594,0-1.374,0-2.135a8.1,8.1,0,0,0,1.07.628,6.886,6.886,0,0,0,2.774.654v-2.719a4.61,4.61,0,0,1-2.8-1.208,4.214,4.214,0,0,1-1.154-2.814c-.308,0-.615,0-.923,0h-.043c-.528,0-1.075,0-1.616.01-.013,1.792-.013,3.683-.012,5.2,0,.819,0,1.894,0,2.944,0,.324,0,.651,0,.968,0,.822,0,1.754-.046,2.648a1.464,1.464,0,0,1-.25.679l-.021.037a2.216,2.216,0,0,1-1.829,1.086q-.082.007-.166.008a2.279,2.279,0,0,1-2.146-1.945c0-.056,0-.113,0-.17a2.214,2.214,0,0,1,.1-.917,2.319,2.319,0,0,1,.9-1.18,2.122,2.122,0,0,1,1.232-.373,2.439,2.439,0,0,1,.763.122c0-.407.006-.813.014-1.264v-.015c.01-.562.02-1.144.011-1.716A4.408,4.408,0,0,0,7437.874-323.908Z"
        transform="translate(-7430.001 331.999)"
        fill="#fff"
      />
    </SvgIcon>
  );
}

export default TikTokEnabled;
