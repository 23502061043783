import React from "react";
import { SvgIcon } from "@mui/material";

function LinkedinEnabled(props) {
  return (
    <SvgIcon viewBox="0 0 20.543 20.543" {...props}>
      <path
        id="Icon_awesome-linkedin"
        data-name="Icon awesome-linkedin"
        d="M19.076,2.25H1.463A1.474,1.474,0,0,0,0,3.731V21.312a1.474,1.474,0,0,0,1.463,1.481H19.076a1.478,1.478,0,0,0,1.467-1.481V3.731A1.478,1.478,0,0,0,19.076,2.25ZM6.209,19.859H3.164v-9.8H6.213v9.8ZM4.686,8.716A1.765,1.765,0,1,1,6.452,6.95,1.766,1.766,0,0,1,4.686,8.716ZM17.622,19.859H14.578V15.09c0-1.137-.023-2.6-1.582-2.6-1.587,0-1.83,1.238-1.83,2.517v4.852H8.121v-9.8h2.921v1.339h.041a3.207,3.207,0,0,1,2.884-1.582c3.082,0,3.655,2.031,3.655,4.673Z"
        transform="translate(0 -2.25)"
        fill="#fff"
      />
    </SvgIcon>
  );
}

export default LinkedinEnabled;
